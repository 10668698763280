import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Divider} from '@material-ui/core';
import {Section} from 'components/organisms';
import {Hero} from './components';
import {SEO} from 'components/atoms';

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(4),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
}));

const pageContext = {
  frontmatter: {
      title: "The Studio Collective",
  }
}

const Landing = () => {
  const classes = useStyles();

  return (
    <div>
      <SEO pageContext={pageContext} />
      <Section className={classes.pagePaddingTop}>
        <Hero />
      </Section>
    </div>
  );
};

export default Landing;
