import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button} from 'gatsby-theme-material-ui';
import {LearnMoreLink} from 'components/atoms';
import {StaticImage} from 'gatsby-plugin-image';
import {useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid} from '@material-ui/core';
import {SectionHeader} from 'components/molecules';
import {HeroShaped} from 'components/organisms';
import {Helmet} from 'react-helmet';
import {Section} from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    '& .hero-shaped': {
      borderBottom: 0,
    },
    '& .hero-shaped__wrapper': {
      minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
    },
    height: "100%",
  },
  image: {
    objectFit: 'cover',
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  section: {
    height: '100%',
  },
}));

const NotFoundCover = () => {
  const classes = useStyles();

  const handleClick = () => {
    const isSSR = typeof window === 'undefined';
    if (!isSSR) window.history.back();
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>404 not found | Studio Collective</title>
      </Helmet>
      <Section className={classes.section}>
      <Grid container alignItems="center" justify="center" direction="column" spacing={0} style={{height: "100%", display: "flex"}}>
        <Grid item xs={12} md={6} data-aos="fade-up">
          <SectionHeader
            fadeUp
            label="404"
            title="Uh oh."
            subtitle={
              <span>
                There’s nothing here, but if you feel this is an error please{' '}
                <LearnMoreLink
                  title="let us know"
                  href="mailto:tosh@studiocollective.xyz"
                  typographyProps={{variant: 'h6'}}
                />
              </span>
            }
            titleProps={{
              variant: 'h3',
            }}
            labelProps={{
              color: 'secondary',
              className: classes.label,
              variant: 'h5',
            }}
            disableGutter
          />
        </Grid>
      </Grid>
    </Section>
    </div>
  );
};

export default NotFoundCover;
