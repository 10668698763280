/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import {NotFoundCover} from 'views';
import DocsLayout from 'layouts/DocsLayout';
import WithLayout from 'WithLayout';

const FourOFourPage = () => <WithLayout component={NotFoundCover} layout={DocsLayout} />;

export default FourOFourPage;
