import React from 'react';
import PropTypes from 'prop-types';
import {useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Grid, Typography} from '@material-ui/core';
import {Button, Link} from 'gatsby-theme-material-ui';
import {Video, Image} from 'components/atoms';
import {SectionHeader} from 'components/molecules';

const Hero = props => {
  const {className, ...rest} = props;

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container alignItems="center" justify="space-between" spacing={isMd ? 5 : (isSm ? 4 : 3 )}>
        <Grid item xs={12} md={12} data-aos="fade-up">
          <SectionHeader
            title={
              <span style={{fontWeight:"400"}}>
                the{' '}
                <Typography component="span" variant="inherit" color="primary" style={{fontWeight:"700"}}>
                  Studio Collective
                </Typography>
              </span>
            }
            subtitle={
              <span>
                <br/>
                The Studio Collective is a community of musicians and visual artists based in San Francisco, curated by <Link to="/tosh" color="inherit" ><b>Tōsh</b></Link>. 
                <br/>
                {!isLg && <br/>}
                Explore our <Link to="/showcases" color="inherit"><b>showcases</b></Link>, watch our recent <Link to="/talks" color="inherit"><b>artist talks</b></Link>, and check out our first <Link to="/introspect" color="inherit"><b>vinyl release</b></Link>.
                <br/>
              </span>
            }
            align='center'
            disableGutter
            titleVariant="h3"
          />
        </Grid>
        <Grid item container justify="center" alignItems="center" xs={12} md={12} data-aos="fade-up">
        <Image src='https://res.cloudinary.com/studiocollective/image/upload/v1723999807/Studio%20Collective/Studio%20Pictures/7480A6DC-8CDB-4BA2-8DCA-EA1F5FCC8163_fkxzhp.jpg'  width='100%'/>
          {/* <Video videoId="xRSuVyaLLPE" alt="Issue #1: Live Coding" preview /> */}
        </Grid>
      </Grid>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
